<template>

  <div :class="'slider-container ' + (isEditable ? 'editable' : '')">


    <div class="names">
      <div class="name low">Sleepy <i class="icon-battery-low"></i></div>
      <!-- <div class="name">Hybrid</div> -->
      <div class="name high">Energy <i class="icon-battery-high"></i></div>
    </div>

    <div class="bars"
      @mousedown.prevent="startDrag"
      @touchstart.prevent="startDrag"
    >
      <div
        v-for="(barColor, index) in barColors"
        :key="index"
        class="bar"
        :style="{ backgroundColor: barColor }"
      ></div>
    </div>

    <div 
      class="thumb"
      :style="thumbStyle"
      @mousedown.prevent="startDrag"
      @touchstart.prevent="startDrag"
    >
      <div class="triangle_up"></div>
    </div>



    <div class="names secondary">
      <div class="name low">
        Indica 
        <div class="vl i" v-if="isEditable">
          {{ 100 - modelValue }}%
        </div>
      </div>
      <div class="name">Hybrid</div>
      <div class="name high">
        <div class="vl s" v-if="isEditable">
          {{ modelValue }}%
        </div>
        Sativa
      </div>
    </div>


  </div>
</template>

<script setup lang="ts">

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0
  },
  isEditable: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const stepsCount = 30

const internalValue = ref(props.modelValue)


watch(
  () => props.modelValue,
  newVal => {
    internalValue.value = newVal
  }
)


const barColors = [
  '#ea4b18','#ed581f','#f26628','#f57732','#f8883d','#fb9b4d',
  '#fdb05d','#fec76e','#ffdd84','#ffed9f','#fffbaf','#f2febf',
  '#e2fab2','#d1f4a6','#c2eca0','#afe2aa','#9eed90','#8eea80',
  '#7ce56f','#6ddf60','#60d852','#4fd33f','#3ecc2c','#38be25',
  '#30b01e','#2ca31d','#268f1c','#22731b','#1e681a','#1a5c1a'
]


const thumbStyle = computed(() => {
  return {
    left: `${internalValue.value}%`
  }
})


let isDragging = false


const startDrag = (e) => {
  if(!props.isEditable) return
  isDragging = true
  addListeners()
  moveThumb(e)
}


const moveThumb = (e) => {
  if (!isDragging) return

  const clientX = e.touches ? e.touches[0].clientX : e.clientX

  const slider = document.querySelector('.slider-container')
  if (!slider) return

  const rect = slider.getBoundingClientRect()
  const offsetX = clientX - rect.left

  let newValue = (offsetX / rect.width) * 100
  newValue = Math.min(100, Math.max(0, newValue))

  internalValue.value = Math.round(newValue)
  emit('update:modelValue', internalValue.value)
}


const stopDrag = () => {
  isDragging = false
  removeListeners()
}

function addListeners() {
  document.addEventListener('mousemove', moveThumb)
  document.addEventListener('mouseup', stopDrag)
  document.addEventListener('touchmove', moveThumb)
  document.addEventListener('touchend', stopDrag)
}

function removeListeners() {
  document.removeEventListener('mousemove', moveThumb)
  document.removeEventListener('mouseup', stopDrag)
  document.removeEventListener('touchmove', moveThumb)
  document.removeEventListener('touchend', stopDrag)
}

onMounted(() => {
  internalValue.value = props.modelValue
})

onBeforeUnmount(() => {
  removeListeners()
})
</script>

<style scoped>

.slider-container {
  position: relative;
  width: 100%; 
  max-width: 600px;
  user-select: none;
  margin-top: 0.5rem;
  margin: 0 auto;
}


.bars {
  display: flex;
  height: 20px;
  width: 100%;
  gap: 0.1rem;
}

.bar {
  flex: 1 1 auto;
}
.bar:first-child {
  border-radius: 5px 0 0 5px;
}
.bar:last-child {
  border-radius: 0 5px 5px 0;
}

.thumb {
  position: absolute;
  width: 13px;
  height: 30px;
  cursor: pointer;
  transform: translateX(-50%);
  border: 2px var(--un-background-color-invert) solid;
  border-radius: 4px;
  pointer-events: none;
  margin-top: -24px;
  position: relative;
}
.thumb::after {
  content: ' ';
  position: absolute;
  z-index: 1;
  top: -20px;
  left: -10px;
  right: -10px;
  bottom: -20px;
  cursor: pointer;
}

.editable .thumb {
  pointer-events: auto;
}

.names{
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.names .name{
  /* width: 33.33%; */
  text-align: center;
  font-weight: bold;
  display: flex;
  gap: 0.5rem;
}
.names.secondary .name{
  font-weight: normal;
  color: var(--un-text-color-gray);
}
.names .name i{
  font-size: 1rem;
  margin-bottom: -5px;
}
.names .name.low i{  
  color: red;
}
.names .name.high i{
  color: rgb(20, 172, 0);
}

.names .name:first-child{
  margin-right: auto;
}

.names .name:last-child{
  margin-left: auto;
}

.triangle_up{
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--un-background-color-invert);
    position: absolute;
    bottom: 0px;
    left: 0;
}

.vl{ 
  min-width: 40px;
}

.vl.i{ 
  text-align: left;
}

.vl.s{ 
  text-align: right;
}

@container pb (max-width: 600px) {
  .slider-container {
    width: 100%;
  }
}


  
</style>
